@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Poppins', sans-serif;
}

.dialog-custom{
  max-width: 1300px;
}


@media (max-width: 400px),
(max-height: 550px) {
    .autocomplete-wrapper {
        width: 100% !important;
      }
}

.autocomplete-wrapper .spinner-border  {
  position: absolute;
  right: 17px;
  top: 15px;
  width: 1.5rem;
  height: 1.5rem;
  color: lightgray;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid lightgray;
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.30) 0px -30px 36px -28px inset;
}

.autocomplete-wrapper .item  {
  border-bottom: 1px solid lightgray;
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}
.invoice-table-th {
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"13px";
    border: 0 !important;
    border-right:1px solid black !important;
    padding:10px 10px
}
.invoice-table-th-pdf {
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"12px";
    border: 0 !important;
    border-right:1px solid black !important;
    padding:5px
}

.invoice-table-th-last-child{
    background-color: #a1bfa1 !important;
    color: rgb(30, 30, 45);
    font-size:"13px";
    border: 0 !important;
    padding:10px 10px;
}
